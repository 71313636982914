@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;700&display=swap");
*,
::before,
::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: Raleway;
}

.landing {
  background-color: #080808;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  align-items: center;
}
.landing__content {
  padding: 1.5rem;
  border-radius: 15px;
  text-align: center;
  max-width: max-content;
  margin: 0 auto;
  letter-spacing: 1px;
  line-height: 3rem;
}
.landing__content .social-links {
  margin-bottom: 3rem;
  padding-top: 2rem;
}
.landing__content .social-links .links-wrapper {
  display: flex;
  justify-content: center;
}
.landing__content .social-links .links-wrapper .links-item {
  list-style-type: none;
  padding: 0;
}
.landing__content .social-links .links-wrapper .links-item .social-icon {
  color: #fff;
  font-size: 2.25rem;
  margin-left: 8px;
  margin-right: 8px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.landing__content .social-links .links-wrapper .links-item .social-icon:hover {
  color: #08afb7;
}
.landing__content h1 {
  color: #fff;
  font-size: 3rem;
  font-weight: 350;
}
.landing__content--name {
  color: #e01b6c;
  font-weight: 500;
  font-size: 2.7rem;
}
.landing__content .motivational-quotes {
  color: white;
  margin-top: 1rem;
}
.landing__content .motivational-quotes p {
  font-style: italic;
}
.landing__content .motivational-quotes p::first-letter {
  font-size: 1.8rem;
}
.landing__content button {
  background: transparent;
  font-size: 1.25rem;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  text-decoration: none;
  color: #fff;
  border: 1px solid #fff;
  padding: 9px 15px;
  display: inline-block;
  margin: 2.8rem auto;
  letter-spacing: 1px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.landing__content button .arrow-right {
  width: max-content;
  height: max-content;
  margin-bottom: -0.2rem;
  margin-left: 0.5rem;
  transition: all 0.3s linear;
}
.landing__content button:hover {
  background: #08afb7;
}
.landing__content button:hover .arrow-right {
  transform: rotate(90deg);
}

nav {
  position: sticky;
  top: 0;
  height: 3.5rem;
  background-color: #1b242f;
  border-bottom: 3px solid #04c2c9;
  z-index: 9999999999;
  padding: 10px 2rem 0 10%;
  display: flex;
}
nav .bars {
  padding: 5px;
  display: none;
}
nav .bars__icon {
  color: #fff;
}
nav .navLinks ul {
  width: 1160px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  list-style-type: none;
}
nav .navLinks ul li {
  padding: 7px 45px 7px 0;
}
nav .navLinks ul li .active {
  color: #e01b6c !important;
}
nav .navLinks ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 1.125rem;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
}
nav .navLinks ul li a:hover {
  color: #e01b6c;
}

.wrapper-about {
  background: #fff;
}
.wrapper-about .about {
  max-width: 1160px;
  margin: 0 auto;
  padding: 3rem 0;
}
.wrapper-about .about h1 {
  font-size: 2.5rem;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  margin-top: 4rem;
  margin-bottom: 4rem;
  color: #45474a;
}
.wrapper-about .about .features {
  display: flex;
}
.wrapper-about .about .features .feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.wrapper-about .about .features .feature .icon {
  font-size: 2.5rem;
  display: block;
  margin: 0 auto;
  position: relative;
  width: 100px;
  height: 100.12px; /* width * 0.866 */
  background: #04c2c9;
  box-sizing: border-box;
  clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
  position: relative;
}
.wrapper-about .about .features .feature .icon h1 {
  text-align: center;
  color: #fff;
  height: 100%;
  margin-top: 27%;
}
.wrapper-about .about .features .feature_name {
  margin-top: 2rem;
  color: #626262;
}
.wrapper-about .about .features .feature_text {
  color: #726f6f;
  letter-spacing: 0.3px;
  padding: 5px;
}
.wrapper-about .about .me {
  display: flex;
  padding: 4rem 0;
  justify-content: center;
  text-align: center;
}
.wrapper-about .about .me_info {
  background: #fff;
  z-index: 2;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.wrapper-about .about .me_info--image {
  display: block;
  margin: 0 auto;
  position: relative;
  width: 320px;
  height: 277.12px; /* width * 0.866 */
  box-sizing: border-box;
  clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
}
.wrapper-about .about .me_info--image .myphoto {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  overflow: visible;
}
.wrapper-about .about .me_info--name {
  padding: 1rem;
  color: #626262;
}
.wrapper-about .about .me_info p {
  text-align: center;
  align-items: center;
  padding: 0 2rem;
  line-height: 1.3rem;
  font-size: 1rem;
  color: #726f6f;
  font-weight: 400;
}
.wrapper-about .about .me_info p a {
  display: block;
}
.wrapper-about .about .me_skills {
  width: 50%;
  padding: 0;
  margin: 0;
}
.wrapper-about .about .me_skills .me_skill {
  position: relative;
  top: 0rem;
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
}
.wrapper-about .about .me_skills .me_skill--bar {
  display: flex;
  margin-bottom: 15px;
}
.wrapper-about .about .me_skills .me_skill--bar .b {
  padding: 5px;
}
.wrapper-about .about .me_skills .me_skill--bar .name_bar {
  width: 25%;
  color: #fff;
  font-weight: 600;
  font-size: 0.9rem;
  background: #04c2c9;
  z-index: 1;
}
.wrapper-about .about .me_skills .me_skill--bar .bar_cover {
  width: 100%;
  background: #eeeeee;
  z-index: 0;
  overflow: hidden;
}
.wrapper-about .about .me_skills .me_skill--bar .bar_cover .progress_bar {
  height: 100%;
  background: #00a1a7;
}
.wrapper-about .about .me_skills .me_skill--bar .bar_cover [data-aos=rising-bar] {
  transform: translateX(-100%);
  background-color: transparent;
  transition: transform 1s ease-in-out;
  transition: all 1s linear;
}
.wrapper-about .about .me_skills .me_skill--bar .bar_cover [data-aos=rising-bar].aos-animate {
  transform: translateX(0);
  background-color: #00a1a7;
  transition: all 1s linear;
}
.wrapper-about .about .me_skills .me_skill--bar .percentage {
  width: 10%;
  background: #eeeeee;
  text-align: right;
}

.wrapper-project {
  background: #f5f5f5 !important;
}
.wrapper-project .projects {
  padding: 8rem 0;
  max-width: 1160px;
  margin: 0 auto;
  min-height: 100vh;
}
.wrapper-project .projects .title {
  font-size: 2.5rem;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 1rem;
  color: #45474a;
}
.wrapper-project .projects_main {
  display: flex;
  flex-direction: column;
}
.wrapper-project .projects_main .tabs {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3rem 0;
}
.wrapper-project .projects_main .tabs li {
  display: flex;
}
.wrapper-project .projects_main .tabs li .button {
  display: block;
  padding: 9px 30px;
  margin: 0 1rem;
  border: none;
  font-weight: 600;
  font-size: 0.9rem;
  color: #726f6f;
  transition: all 0.3s linear;
  background-color: transparent;
  cursor: pointer;
}
.wrapper-project .projects_main .tabs li .button-live {
  background: #e01b6c;
  color: #fff;
}
.wrapper-project .projects_main .projects_list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  gap: 5px;
}
.wrapper-project .projects_main .projects_list--item {
  position: relative;
}
.wrapper-project .projects_main .projects_list--item .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 1.5rem 0;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  opacity: 0;
  transition: all 0.3s linear;
}
.wrapper-project .projects_main .projects_list--item .overlay_text {
  text-align: center;
}
.wrapper-project .projects_main .projects_list--item .overlay_text .technologies {
  color: #e01b6c;
}
.wrapper-project .projects_main .projects_list--item .overlay .button {
  padding: 0.8rem 1.5rem;
  margin: 0;
  border: none;
  color: #fff;
  font-weight: 500;
  font-size: 1rem;
  background: #e01b6c;
  transition: all 0.2s linear;
}
.wrapper-project .projects_main .projects_list--item .overlay .button:hover {
  outline: 2px solid #e01b6c;
  color: #e01b6c;
  background: none;
}
.wrapper-project .projects_main .projects_list--item .overlay:hover {
  opacity: 1;
}
.wrapper-project .projects_main .projects_list--item img {
  border-radius: 5px;
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: center top;
  display: block;
}

.modal {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  background: linear-gradient(to right, rgba(39, 39, 39, 0.7), rgba(39, 39, 39, 0.7));
  opacity: 3;
  color: #fff;
}
.modal .wrapper {
  max-width: 568px;
  margin: 6rem auto;
}
.modal .wrapper .modal_slider {
  display: flex;
  position: relative;
  overflow: hidden;
  height: 300px;
}
.modal .wrapper .modal_slider .nextImg {
  transform: translateX(100%);
  position: absolute;
}
.modal .wrapper .modal_slider .prevImg {
  transform: translateX(-100%);
  position: absolute;
}
.modal .wrapper .modal_slider img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translateX(0);
  position: absolute;
  transition: all 0.5s linear;
}
.modal .wrapper .modal_slider .icons {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: absolute;
  color: #fff;
  bottom: 0;
  width: 100%;
}
.modal .wrapper .modal_slider .icons .prev,
.modal .wrapper .modal_slider .icons .next {
  padding: 5px;
  font-size: 2rem;
  background: #999999;
}
.modal .wrapper .modal_slider .icons .prev:hover,
.modal .wrapper .modal_slider .icons .next:hover {
  color: #fff;
  background: #e01b6c;
}
.modal .wrapper .modal_footer {
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;
}
.modal .wrapper .modal_footer--title {
  margin-top: 0.8rem;
  color: #515151;
}
.modal .wrapper .modal_footer--work {
  color: #515151;
  font-size: 0.7rem;
  font-weight: 600;
  opacity: 0.6;
  letter-spacing: 0.5px;
  margin: 5px 0 0.7rem 0;
}
.modal .wrapper .modal_footer hr {
  opacity: 0.3;
}
.modal .wrapper .modal_footer--description {
  color: #515151;
  font-weight: 500;
  margin: 1rem 0;
}
.modal .wrapper .modal_footer .base {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal .wrapper .modal_footer .base button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 9px 15px;
  background: #e01b6c;
  border: none;
  font-size: 0.7rem;
  transition: all 0.3s linear;
}
.modal .wrapper .modal_footer .base button .open-in-new {
  color: #fff;
  display: block;
}
.modal .wrapper .modal_footer .base button a {
  text-decoration: none;
  color: #fff;
}
.modal .wrapper .modal_footer .base button:hover {
  background-color: #fff;
  outline: 1px solid #e01b6c;
}
.modal .wrapper .modal_footer .base button:hover .open-in-new {
  color: #e01b6c;
}
.modal .wrapper .modal_footer .base button:hover a {
  color: #e01b6c;
}
.modal .wrapper .modal_footer .base p {
  color: #999999;
  display: block;
  font-size: 1.2rem;
  font-weight: 400;
  opacity: 0.77;
}
.modal .wrapper .modal_footer .base p:hover {
  color: #666666;
  transform: scale(1.2);
}

.wrapper-blog .blog {
  max-width: 1160px;
  margin: 0 auto;
  background: #ffffff;
  padding: 5rem 0;
}
.wrapper-blog .blog h1 {
  font-size: 2.5rem;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  margin-top: 4rem;
  margin-bottom: 4rem;
  color: #45474a;
}
.wrapper-blog .blog .blogs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}
.wrapper-blog .blog .blogs .blog_link {
  text-decoration: none;
  color: #414141;
}
.wrapper-blog .blog .blogs .blog .image {
  height: 250px;
  overflow: hidden;
}
.wrapper-blog .blog .blogs .blog .image img {
  display: block;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.wrapper-blog .blog .blogs .blog_title {
  text-transform: capitalize;
  color: #414141;
  font-size: 1.2rem;
  margin: 0.8rem 0 0.5rem 0 !important;
}
.wrapper-blog .blog .blogs .blog_date {
  font-size: 0.8rem;
  margin-top: 0.5rem 0;
  opacity: 0.7;
}
.wrapper-blog .blog .blogs .blog .hr {
  width: 20%;
  height: 4px;
  background: rgb(45, 242, 242);
  border-radius: 5px;
  color: rgb(45, 242, 242);
  margin: 0.5rem 0 !important;
}
.wrapper-blog .blog .blogs .blog_content {
  font-size: 0.8rem;
  opacity: 0.8;
}

.wrapper-contact {
  background: #202124;
  clip-path: polygon(0% 0%, 50% 10%, 100% 0%, 100% 100%, 0% 100%);
}
.wrapper-contact .contact {
  max-width: 1160px;
  margin: 0 auto;
  padding: 3rem 0 10rem 0;
}
.wrapper-contact .contact h1 {
  font-size: 2.5rem;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  margin-top: 6rem;
  margin-bottom: 2rem;
  color: #fff;
}
.wrapper-contact .contact h1 .underline-contact {
  background: #fff !important;
}
.wrapper-contact .contact p {
  margin-bottom: 2rem;
  text-align: center;
  color: rgb(55, 207, 207);
}
.wrapper-contact .contact form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 400px;
  margin: 0 auto;
  color: #fff;
}
.wrapper-contact .contact form label {
  width: 100%;
}
.wrapper-contact .contact form label input {
  color: #fff;
  width: 100%;
  background: #18191c;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 1rem;
}
.wrapper-contact .contact form label textarea {
  color: #fff;
  width: 100%;
  background: #18191c;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 1rem;
}
.wrapper-contact .contact form button {
  padding: 0.8rem 1.7rem;
  align-self: flex-end;
  color: #fff;
  border: none;
  outline: 1px solid #fff;
  background: transparent;
  letter-spacing: 1px;
  transition: all 0.4s all;
}
.wrapper-contact .contact form button:hover {
  background: rgb(55, 207, 207);
  outline: none;
  font-weight: 5600;
}

.wrapper-footer {
  background-color: #1b242f;
  color: #fff;
  padding: 3rem;
  position: relative;
}
.wrapper-footer .footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.wrapper-footer .footer ul {
  display: flex;
  list-style-type: none;
  gap: 15px;
}
.wrapper-footer .footer ul li {
  background: #2b3644;
  transition: all 0.3s linear;
}
.wrapper-footer .footer ul li a {
  display: block;
  padding: 0.5rem;
  font-size: 1.5rem;
  color: #fff;
}
.wrapper-footer .footer ul li:hover {
  background-color: rgb(55, 207, 207);
}
.wrapper-footer .footer article {
  margin: 1rem;
  color: #eeeeee;
  opacity: 0.7;
  display: flex;
  align-items: center;
}
.wrapper-footer .footer article span {
  color: #fa6db3;
  margin-left: 5px;
}
.wrapper-footer .footer .scroll-to-top {
  font-size: 2rem;
  background-color: #e01b6c;
  position: fixed;
  bottom: 2rem;
  right: 1rem;
  z-index: 99;
}
.wrapper-footer .footer .scroll-to-top:hover {
  background-color: #fff;
  outline: 1px solid #e01b6c;
  color: #e01b6c;
}

@media screen and (max-width: 692px) {
  .landing__content {
    padding: 1rem;
    line-height: 2.3rem;
  }
  .landing__content h1 {
    font-size: 2rem;
  }
  .landing__content--name {
    font-size: 2.1rem;
  }
  .landing__content .motivational-quotes p {
    font-style: italic;
    font-size: 0.6rem;
  }
  .landing__content .motivational-quotes p::first-letter {
    font-size: 1.8rem;
  }
  .landing__content button {
    font-size: 1.125rem !important;
    font-family: "Courier New", Courier, monospace;
    padding: 7px 11px;
    letter-spacing: 1px;
    transition: all 0.3s linear;
  }
  nav {
    height: 3rem;
    position: sticky !important;
    top: 0 !important;
    padding: 0 1rem;
  }
  nav ul li {
    padding: 7px 30px 7px 0;
  }
  nav ul li a {
    font-size: 1rem;
  }
  .wrapper-about .about {
    padding: 3rem 0;
  }
  .wrapper-about .about h1 {
    font-size: 1.75rem;
  }
  .wrapper-about .about .features .feature .icon {
    font-size: 2.5rem;
    width: 75px;
    height: 75px; /* width * 0.866 */
  }
  .wrapper-about .about .features .feature_name {
    margin-top: 1rem;
    font-size: 1rem;
  }
  .wrapper-about .about .features .feature_text {
    letter-spacing: 0.3px;
    font-size: 0.8rem !important;
  }
  .wrapper-about .about .me {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
  .wrapper-about .about .me_info {
    width: 90%;
  }
  .wrapper-about .about .me_info--image {
    width: 240px;
    height: 216.5px;
  }
  .wrapper-about .about .me_info--name {
    padding: 1rem;
    color: #626262;
    font-size: 1rem;
  }
  .wrapper-about .about .me_info p {
    font-size: 0.8rem !important;
  }
  .wrapper-about .about .me_skills {
    width: 90%;
  }
  .wrapper-about .about .me_skills .name_bar {
    font-size: 0.7rem !important;
  }
  .wrapper-project .projects .title {
    font-size: 1.75rem !important;
  }
  .wrapper-project .projects_main .tabs {
    margin: 2rem 0;
  }
  .wrapper-project .projects_main .tabs li {
    padding: 0;
  }
  .wrapper-project .projects_main .tabs li .button {
    padding: 7px 15px !important;
    font-weight: 500;
    font-size: 0.7rem;
  }
  .wrapper-project .projects_main .projects_list {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 5px;
    width: 100%;
    gap: 5px;
  }
  .wrapper-project .projects_main .projects_list--item .overlay_text .name {
    font-weight: 500;
  }
  .wrapper-project .projects_main .projects_list--item .overlay .button {
    padding: 0.5rem 0.7rem;
    margin: 0;
    font-weight: 500;
    font-size: 0.8rem;
  }
  .wrapper-project .projects_main .projects_list--item img {
    height: 250px;
  }
  .wrapper-blog .blog h1 {
    font-size: 1.75rem !important;
  }
  .wrapper-blog .blog .blogs {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 5px !important;
    gap: 5px;
  }
  .wrapper-blog .blog .blogs .blog {
    padding: 1rem 0;
  }
  .wrapper-blog .blog .blogs .blog_title {
    text-transform: capitalize;
    color: #414141;
    font-size: 1rem;
    margin: 0.3rem 0;
  }
  .wrapper-blog .blog .blogs .blog_date {
    font-size: 0.6rem;
    margin-top: 0.2rem 0;
  }
  .wrapper-blog .blog .blogs .blog .hr {
    height: 2px;
    margin: 0.7rem 0;
  }
  .wrapper-contact {
    clip-path: polygon(0% 0%, 50% 5%, 100% 0%, 100% 100%, 0% 100%);
  }
  .wrapper-contact .contact {
    padding: 1rem 0 7rem 0;
  }
  .wrapper-contact .contact h1 {
    font-size: 1.5rem !important;
  }
  .wrapper-contact .contact p {
    font-size: 0.8rem !important;
  }
  .wrapper-contact .contact form input {
    font-size: 0.8rem !important;
  }
  .wrapper-contact .contact form textarea {
    font-size: 0.9rem !important;
  }
  .wrapper-contact .contact form button {
    padding: 0.5rem 1rem;
    align-self: center !important;
  }
  .wrapper-footer .footer ul {
    gap: 15px;
  }
  .wrapper-footer .footer ul li a {
    display: block;
    padding: 0.5rem;
    font-size: 1.125rem;
    color: #fff;
  }
  .wrapper-footer .footer article {
    font-size: 0.8em;
  }
  .wrapper-footer .footer .scroll-to-top {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 480px) {
  .landing__content {
    padding: 0.5rem;
    line-height: 2rem;
  }
  .landing__content h1 {
    font-size: 1.3rem;
  }
  .landing__content--name {
    font-size: 1.5rem;
  }
  .landing__content button {
    font-size: 0.7rem;
    padding: 7px 11px;
    letter-spacing: 1px;
    transition: all 0.3s linear;
    background: #08afb7;
    border: 2px solid #08afb7;
  }
  nav {
    height: 3rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0;
    position: relative;
  }
  nav .bars {
    display: block;
    position: absolute;
    right: 0.5rem;
    height: 100%;
    display: flex;
    align-items: center;
  }
  nav .bars__icon {
    font-size: 25px;
  }
  nav .navLinks {
    align-self: flex-start;
    position: absolute;
    margin-top: 2.5rem;
    width: 100%;
    overflow: hidden;
    transition: all 0.3s linear;
    background: #242c3c;
  }
  nav .navLinks ul {
    padding: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .wrapper-about .about .features {
    display: grid;
    gap: 1.5rem;
  }
  .wrapper-about .about .features .feature .icon {
    width: 80px;
    height: 80px; /* width * 0.866 */
  }
  .wrapper-about .about .features .feature_text {
    letter-spacing: 0.3px;
    font-size: 0.9rem;
    padding: 0.3rem 2rem;
  }
  .wrapper-project .projects .title {
    font-size: 2.125rem;
  }
  .wrapper-project .projects_main .tabs {
    margin: 2rem 0;
  }
  .wrapper-project .projects_main .tabs li {
    padding: 0;
  }
  .wrapper-project .projects_main .tabs li .button {
    margin: 0;
    padding: 7px 7px !important;
    font-weight: 500;
    font-size: 0.7rem;
  }
  .wrapper-project .projects_main .projects_list {
    grid-template-columns: repeat(1, 1fr) !important;
    padding: 0 5px;
    width: 100%;
    gap: 5px;
  }
  .wrapper-project .projects_main .projects_list--item .overlay_text .name {
    font-weight: 500;
  }
  .wrapper-project .projects_main .projects_list--item .overlay .button {
    padding: 0.5rem 0.7rem;
    margin: 0;
    font-weight: 500;
    font-size: 0.8rem;
  }
  .wrapper-project .projects_main .projects_list--item img {
    height: 250px;
  }
  .wrapper-blog .blog h1 {
    font-size: 2.125rem;
  }
  .wrapper-blog .blog .blogs {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 5px;
    gap: 5px;
  }
  .wrapper-contact {
    clip-path: polygon(0% 0%, 50% 5%, 100% 0%, 100% 100%, 0% 100%);
  }
  .wrapper-contact .contact {
    padding: 1rem 0 7rem 0;
  }
  .wrapper-contact .contact h1 {
    font-size: 2.125rem;
  }
  .wrapper-contact .contact form {
    width: 100%;
    padding: 1rem;
  }
  .wrapper-contact .contact form button {
    padding: 0.5rem 1rem;
    align-self: center !important;
  }
  .wrapper-footer .footer ul {
    gap: 15px;
  }
  .wrapper-footer .footer ul li a {
    display: block;
    padding: 0.5rem;
    font-size: 1.125rem;
    color: #fff;
  }
  .wrapper-footer .footer article {
    font-size: 0.8em;
  }
  .wrapper-footer .footer .scroll-to-top {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 480px) {
  .modal {
    overflow-y: scroll !important;
  }
  .modal-slider {
    padding: 0.5rem !important;
  }
  .modal_footer {
    padding: 0.5rem !important;
  }
}
.underline {
  height: 0.25rem;
  width: 4rem;
  background: #45474a;
  margin-left: auto;
  margin-right: auto;
}

.active-btn {
  color: #e01b6c;
}